import { CustomerResponse } from '@models/customer';
import { translate } from '@components/i18n';
import { SHOW_COUNTRY } from '@config/env';

export const columns = [
    {
        title: translate('columns.client'),
        dataIndex: 'name',
        sorter: true,
        width: '25%',
        render: (_, record: CustomerResponse): React.ReactNode => {
            const addresses = []

            if (record?.address?.trim()) {
                addresses.push(record.address)
            } else if (record?.alternativeAddress?.trim()) {
                addresses.push(record.alternativeAddress)
            }

            return (
                <>
                    <span
                        style={{
                            display: 'flex',
                            columnGap: '5px',
                            userSelect: 'none',
                            fontWeight: 'bold'
                        }}
                    >
                        {record.name}
                    </span>
                    <span
                        style={{
                            fontStyle: 'italic',
                            fontSize: '0.850em'
                        }}>
                        {translate("columns.address")}: {addresses.join(" / ")}
                    </span>
                </>
            );
        },
    },
    {
        title: translate('columns.code'),
        dataIndex: 'code',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.cia'),
        dataIndex: 'company',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.type'),
        dataIndex: 'type',
        sorter: true,
        width: '10%',
        render: (_, record: CustomerResponse): React.ReactNode => {
            return (
                <span
                    style={{
                        display: 'flex',
                        columnGap: '5px',
                        userSelect: 'none',
                    }}
                >
                    {record.typeId} - {record.type}
                </span>
            );
        },
    },
    {
        title: translate('columns.city'),
        dataIndex: 'city',
        sorter: true,
        width: '15%',
    },
    {
        title: translate('columns.state'),
        dataIndex: 'state',
        sorter: true,
        width: '10%',
    },
    {
        title: translate('columns.country'),
        dataIndex: 'country',
        sorter: true,
        width: '10%',
        noShow: !SHOW_COUNTRY
    },
    {
        title: translate('columns.cnpj'),
        dataIndex: 'cnpj',
        sorter: true,
        width: '10%',
    },
];

export const filterTypes = [
    {
        name: 'todos',
        description: translate('filters.all'),
    },
    {
        name: 'cnpj',
        description: translate('filters.cnpj'),
    },
    {
        name: 'nome',
        description: translate('filters.name'),
    },
    {
        name: 'codigo',
        description: translate('filters.clientCode'),
    },
    {
        name: 'cidade',
        description: translate('filters.city'),
    },
    {
        name: 'estado',
        description: translate('filters.state'),
    },
    {
        name: 'tipo',
        description: translate('filters.type'),
    },
];

export const filterTypesOrder = [
    {
        name: 'CNPJ_CLIENTE',
        description: translate('filters.cnpj'),
    },
    {
        name: 'NOME_CLIENTE',
        description: translate('filters.name'),
    },
    {
        name: 'CODIGO_CLIENTE',
        description: translate('filters.clientCode'),
    },
    {
        name: 'CIDADE_CLIENTE',
        description: translate('filters.city'),
    },
    {
        name: 'ZONA_CLIENTE',
        description: translate('filters.state'),
    },
    {
        name: 'TIPO_PEDIDO',
        description: translate('filters.orderType'),
    },
];

export const filterTypesOrderHistory = [
    {
        name: 'CNPJ_CLIENTE',
        description: 'CNPJ',
    },
    {
        name: 'NOME_CLIENTE',
        description: 'Nome',
    },
    {
        name: 'PEDIDO',
        description: 'Pedido',
    },
    {
        name: 'CODIGO_CLIENTE',
        description: 'Código do Cliente',
    },
    {
        name: 'CIDADE_CLIENTE',
        description: 'Cidade',
    },
    {
        name: 'ZONA_CLIENTE',
        description: 'Estado',
    },
    {
        name: 'TIPO_PEDIDO',
        description: 'Tipo de Pedido',
    },
];

export const filterOptions = [
    { value: 'all', label: 'Todos' },
    { value: 'name', label: 'Nome' },
    { value: 'code', label: 'Código' },
];

export const filterOptionsTranspo = [
    { value: 'all', label: translate('filters.all') },
    { value: 'name', label: translate('filters.name') },
    { value: 'codigoTipoTransporte', label: translate('filters.transport') },
];

export const filterModalSelectOptions = [
    { value: 'todos', label: translate('filters.all') },
    { value: 'nome', label: translate('filters.name') },
    { value: 'codigo', label: translate('filters.code') },
];
