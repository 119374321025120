/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { UserOutlined } from '@ant-design/icons';
import { EditableField } from '@models/editable-field';
import { authSelector, changeCurrentUser } from '@redux/slices/auth';
import { userSelector } from '@redux/slices/users';
import { Avatar, List, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@styles/components/button';
import { translate } from '@components/i18n';
import { REPORT_PERMISSION, ROLES } from 'src/enums/user';

export const columns = [
    {
        title: translate('columns.name'),
        dataIndex: 'name',
        sorter: false,
        width: '15%',
        render: (text, record): React.ReactNode => {
            return (
                <List.Item.Meta
                    style={{ alignItems: 'center' }}
                    avatar={
                        <Avatar src={record.photo} icon={<UserOutlined />} />
                    }
                    title={text}
                />
            );
        },
    },
    {
        title: translate('columns.jdeCode'),
        dataIndex: 'jdeCode',
        sorter: false,
        width: '15%',
    },
    {
        title: translate('columns.roles'),
        dataIndex: 'papeis',
        sorter: false,
        width: '15%',
        render: (_, record): React.ReactNode => {
            const roles = record?.roles.map((papel) => {
                const role = ROLES.find((role) => role.id === papel);
                if (role) return role.name;

                return papel;
            });

            return (
                <span>
                    {roles.map((papel, index) => (
                        <span key={index} style={{ marginRight: 5 }}>
                            {papel}
                        </span>
                    ))}
                </span>
            );
        },
    },
    {
        title: translate('columns.active'),
        dataIndex: 'active',
        sorter: false,
        width: '12%',
        render: (_, record): React.ReactNode => {
            return (
                <span>
                    {record.active
                        ? translate('general.yes')
                        : translate('general.no')}
                </span>
            );
        },
    },
    {
        title: translate('columns.login'),
        dataIndex: 'login',
        sorter: false,
        width: '12%',
    },
    {
        title: translate('columns.division'),
        dataIndex: 'division',
        sorter: true,
        width: '10%',
    },
];

export function selectorUserFields({ userOptions }): EditableField[] {
    return [
        {
            name: 'user',
            label: translate('forms.labels.chooseUserCopy'),
            type: 'select',
            span: 24,
            options: userOptions,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.selectUserCopy'),
                },
            ],
        },
    ];
}

export function editableFields({
    distributors,
}): EditableField[] {
    const usersState = useSelector(userSelector);
    const loggedUser = useSelector(authSelector);
    return [
        {
            name: 'nome',
            label: translate('forms.labels.fullName'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.name'),
                },
                {
                    min: 5,
                    message: translate('forms.rules.fiveCaracteres'),
                },
            ],
        },
        {
            name: 'cnpj',
            label: translate('forms.labels.cnpj'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.required'),
                },
            ],
        },
        {
            name: 'email',
            label: translate('forms.labels.email'),
            type: 'text',
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.email'),
                },
                {
                    type: 'email',
                    message: translate('forms.rules.validEmail'),
                },
            ],
        },
        {
            name: 'codigoDistribuidor',
            label: translate('forms.labels.distributor'),
            type: 'select',
            span: 24,
            options: distributors,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.required'),
                },
            ],
        },
        /* {
            name: 'zona',
            label: translate('forms.labels.zone'),
            type: 'text',
            span: 12,
        }, */
        {
            name: 'endereco1',
            label: translate('forms.labels.address1'),
            type: 'text',
            span: 24,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.required'),
                },
            ],
        },
        {
            name: 'endereco2',
            label: translate('forms.labels.address2'),
            type: 'text',
            span: 24,
        },
        {
            name: 'bairro',
            label: translate('forms.labels.neighborhood'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.required'),
                },
            ],
        },
        {
            name: 'cidade',
            label: translate('forms.labels.city'),
            type: 'text',
            span: 12,
            rules: [
                {
                    required: true,
                    message: translate('forms.rules.required'),
                },
            ],
        },
    ];
}
