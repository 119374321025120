import React, { memo, useContext, useEffect, useState } from 'react';
import { Col, Form, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import { editableFields } from '../constants';
import { FormBuilder } from '@components/form';
import { updateUser } from '@redux/slices/users';
import { fetchApprovalGroups } from '@redux/slices/approval-groups';
import { fetchLines } from '@redux/slices/divisions';
import { translate } from '@components/i18n';
import { TransferClient } from '@models/transfer-client';
import { addTransferClients, deleteTransferClient } from '@redux/slices/transfer-clients';
import { distributorSelector } from '@redux/slices/distributor';

interface EditModalProps {
    data?: TransferClient.Response;
}

export const EditModal: React.FC<EditModalProps> = memo(({ data }) => {
    const distributor = useSelector(distributorSelector)

    const dispatch = useDispatch();
    const [form] = Form.useForm<TransferClient.Response>();
    const [editState, setEditState] = useState<TransferClient.Response>(data);
    const modalContext = useContext(ModalContext);

    const onOk = (values): void => {
        if (!data) addNew(values);
        else handleUpdate(values);
    };

    const handleUpdate = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.updatingTransferClient'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            updateUser({
                data: {
                    id: data.id,
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    const addNew = (values): void => {
        const orderKey = `${new Date().valueOf()}`;
        message.loading({
            content: translate('general.registeringTransferClient'),
            key: orderKey,
            duration: 10,
            className: 'message-styled',
        });

        dispatch(
            addTransferClients({
                data: {
                    ...values,
                },
                notificationKey: orderKey,
                onSuccess: () => {
                    modalContext.handleClose();
                },
            }),
        );
    };

    useEffect(() => {
        dispatch(fetchApprovalGroups());
        dispatch(fetchLines());
    }, []);

    useEffect(() => {
        if (data) {
            setEditState(data);
            form.setFieldsValue(data);
        } else {
            form.resetFields();
        }
    }, [data]);

    const onChangeValues = (
        changedValues: Partial<TransferClient.Response>,
        allValues: TransferClient.Response,
    ): void => {
        setEditState({
            ...allValues,
            ...changedValues,
        });
    };

    const handleDelete = (): void => {
        dispatch(deleteTransferClient(data.id));
        modalContext.handleClose();
    };

    return (
        <Row align="middle">
            <Col span={1} />
            <Col span={22}>
                <FormBuilder
                    onValuesChange={onChangeValues}
                    layout="vertical"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ ...editState }}
                    onFinish={onOk}
                    form={form}
                    fields={editableFields({
                        distributors: distributor?.available?.content?.map(c => ({
                            id: c.id,
                            name: `${c.id} - ${c.nome}`,
                        })) || [],
                    })}
                    withDelete={!!data}
                    onDelete={handleDelete}
                />
            </Col>
        </Row>
    );
});
