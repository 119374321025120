import { RouterPath } from '@definitions/routes';
import { SelectCustomer } from '@pages/transfer-order/select-client';
import { SelectType } from '@pages/order/select-type';

const routes: RouterPath[] = [
  {
    path: '/create-transfer-order/select-customer',
    auth: true,
    render: () => <SelectCustomer />,
    allowedRoles: ['PROMOTOR'],
  },
  {
    path: '/create-order/select-type',
    auth: true,
    render: () => <SelectType />,
    allowedRoles: ['PROMOTOR'],
  },
];

export default routes;
