import axios from 'axios';
import { APP_URL_API } from './env';

const instance = axios.create({
    baseURL: APP_URL_API,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        Accept: '*/*',
        'Access-Control-Allow-Headers':
            'Content-Type, Authorization, Access-Control-Allow-Headers',
        'Access-Control-Allow-Methods': 'POST',
    },
    withCredentials: false,
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('jwtToken');

        if (token) config.headers.Authorization = `Bearer ${token}`;
        else delete instance.defaults.headers.common.Authorization;

        return config;
    },

    (error) => Promise.reject(error),
);

// instance.interceptors.request.use((request) => {
//     console.debug('Starting Request', JSON.stringify(request, null, 2));
//     return request;
// });

instance.interceptors.response.use(
    (response) => {
        console.debug('Response:', JSON.stringify(response, null, 2));
        return response;
    },
    (error) => {
        console.debug('Error:', JSON.stringify(error?.response, null, 2));
        if (401 === error?.response?.status) {
            localStorage.clear();
            window.location.replace('/login');
        } else {
            return Promise.reject(error);
        }
    },
);

export default instance;
