import { combineReducers } from 'redux';

import auth from '@redux/slices/auth';
import order from '@redux/slices/order';
import notification from '@redux/slices/notification';
import users from '@redux/slices/users';
import approvalGroups from '@redux/slices/approval-groups';
import approvalMarketing from './slices/approval-marketing';
import divisions from './slices/divisions';
import delegation from './slices/delegation';
import reports from './slices/reports';
import campaign from './slices/campaign';
import minOrder from './slices/min-order';
import parameters from './slices/config';
import items from './slices/item';
import deadline from './slices/deadline';
import itemDeadline from './slices/item-deadline';
import route from './slices/route';
import cost from './slices/cost';
import semaphore from './slices/semaphore';
import transferClients from './slices/transfer-clients';
import transferItems from './slices/transfer-item';
import distributors from './slices/distributor';


const rootReducer = combineReducers({
    ...auth,
    ...order,
    notification,
    ...users,
    ...approvalGroups,
    ...approvalMarketing,
    ...divisions,
    ...delegation,
    ...reports,
    ...campaign,
    ...minOrder,
    ...parameters,
    ...items,
    ...deadline,
    ...itemDeadline,
    ...route,
    ...cost,
    ...semaphore,
    ...transferClients,
    ...transferItems,
    ...distributors,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
