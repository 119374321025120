import { RouterPath } from '@definitions/routes';
import { TransferClients } from '@pages/transfer-clients';
import { TransferItem } from '@pages/transfer-item';

const routes: RouterPath[] = [
    {
        path: '/transfer-clients',
        render: () => <TransferClients />,
        auth: true,
        allowedRoles: ["PROMOTOR"],
    },
    {
        path: '/transfer-items',
        render: () => <TransferItem />,
        auth: true,
        allowedRoles: ["PROMOTOR"],
    },
];

export default routes;
