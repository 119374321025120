export class DistributorRequestParams {
    filterString?: string;
    filterType?: string;
    page: number;
    size: number;
    sort?: string;
}

export class DistributorRequest {
    bairro: string;
    categoria: string;
    cidade: string;
    cnpj: string;
    codigo: number;
    companhia: number;
    condicaoPagto: string;
    endereco1: string;
    endereco2: string;
    grupo: string;
    isentaIpi: boolean;
    licenca: string;
    nome: string;
    pais: string;
    suframa: string;
    tipo: string;
    tipoDescricao: string;
    trade: string;
    zona: string;

    public toJSON(): DistributorRequestApi {
        return ({
            bairro: this.bairro,
            categoria: this.categoria,
            cidade: this.cidade,
            cnpj: this.cnpj,
            codigo: this.codigo,
            companhia: this.companhia,
            condicaoPagto: this.condicaoPagto,
            endereco1: this.endereco1,
            endereco2: this.endereco2,
            grupo: this.grupo,
            isentaIpi: this.isentaIpi,
            licenca: this.licenca,
            nome: this.nome,
            pais: this.pais,
            suframa: this.suframa,
            tipo: this.tipo,
            tipoDescricao: this.tipoDescricao,
            trade: this.trade,
            zona: this.zona,
        })
    }
}
export class DistributorRequestApi {
    bairro: string;
    categoria: string;
    cidade: string;
    cnpj: string;
    codigo: number;
    companhia: number;
    condicaoPagto: string;
    endereco1: string;
    endereco2: string;
    grupo: string;
    isentaIpi: boolean;
    licenca: string;
    nome: string;
    pais: string;
    suframa: string;
    tipo: string;
    tipoDescricao: string;
    trade: string;
    zona: string;
}
