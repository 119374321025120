import React, { useEffect, useState } from 'react';

import { Input, Page } from '@components';
import { ShadowContainer } from '@styles/components/wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import {
    customerSelector,
    fetchAvailableTypes,
    fetchCategories,
    fetchCustomers,
    selectCustomer,
    updateOrder,
} from '@redux/slices/order';
import { Title } from '@styles/components/text';
import { columns, filterTypes } from './constants';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { CustomerResponse } from '@models/customer';
import { Table } from '@components/table';
import { FormItem, Option, Select } from '@styles/components/form';
import { useHistory } from 'react-router-dom';
import { toQueryParameters } from 'src/extensions/object';
import { SearchOutlined } from '@ant-design/icons';
import { translate } from '@components/i18n';
import { CATEGORY_WITH_VALUE_AR, SHOW_CATEGORY } from '@config/env';

const defaultValues = {
    category: 'Sold'
}

export const SelectCustomer: React.FC = () => {
    const [queryState, setQueryState] = useState<string>('');
    const selectCustomerState = useSelector(customerSelector);
    const dispatch = useDispatch();
    const history = useHistory();
    const [form] = Form.useForm();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFiltersChange = (changedValues: any, values: any): void => {
        dispatch(
            fetchCustomers({
                ...selectCustomerState.request,
                ...values,
                filterValue: queryState,
                categoria: values.category
            }),
        );
    };

    const onSearch = (value: string): void => {
        setQueryState(value);
    };

    useEffect(() => {
        history.replace(
            '/create-transfer-order/select-customer?' +
            toQueryParameters(selectCustomerState.request),
        );
    }, [selectCustomerState.request]);

    useEffect(() => {
        if (SHOW_CATEGORY) {
            dispatch(fetchCategories())
        }
    }, []);

    useEffect(() => {
        if (SHOW_CATEGORY) {
            if (selectCustomerState.isSuccessCategories) {
                if (selectCustomerState.categories.length > 0) {
                    const hasSold = selectCustomerState.categories.find(category => category.nome === 'Sold')
                    if (hasSold) {
                        dispatch(fetchCustomers(({
                            ...selectCustomerState.request,
                            ...(!!CATEGORY_WITH_VALUE_AR && { categoria: defaultValues.category })
                        })));
                    }
                } else {
                    dispatch(fetchCustomers(selectCustomerState.request));
                }

            }
        } else {
            dispatch(fetchCustomers(selectCustomerState.request));
        }
    }, [selectCustomerState.isSuccessCategories])

    const loadData = (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        pagination: any,
        filters: Record<string, FilterValue>,
        sorter: SorterResult<unknown>,
    ): void => {
        dispatch(
            fetchCustomers({
                ...pagination,
                field: sorter.field,
                order: sorter.order,
            }),
        );
    };

    const onSelect = (rowKey: CustomerResponse): void => {
        dispatch(selectCustomer(rowKey));
        dispatch(updateOrder({ customer: rowKey }));
        dispatch(fetchAvailableTypes());
        history.push('/create-order/select-type');
    };

    const search = (): void => {
        dispatch(
            fetchCustomers({
                ...selectCustomerState.request,
                filterValue: queryState,
            }),
        );
    };

    useEffect(() => {
        const filters = Object.fromEntries(
            new URLSearchParams(location.search),
        );

        setTimeout(() => {
            form.setFieldsValue({
                ...filters,
                company: Number(filters.company) || undefined,
                filterBy: filters.filterBy || undefined,
            });
        }, 2000);
    }, []);

    const getInitialValues = (): any => {
        const initialValues = {}
        if (SHOW_CATEGORY && CATEGORY_WITH_VALUE_AR) {
            Object.assign(initialValues, { category: defaultValues.category })
        }
        return initialValues
    }

    return (
        <Page
            title={translate('pages.searchCliente')}
            greyBackground
            withPadding
        >
            <Row>
                <Col span={1} />
                <Col span={22}>
                    <Row>
                        <Title marginTop={20} level={3}>
                            {translate('titlePages.searchCliente')}
                        </Title>
                    </Row>
                    <ShadowContainer>
                        <Row justify="center">
                            <Col span={23}>
                                <Table
                                    customKey={`${selectCustomerState.request.current}-${selectCustomerState.request.company}-page`}
                                    columns={columns.filter(column => !column.noShow)}
                                    form={form}
                                    pointer
                                    rowKey={(record: CustomerResponse) =>
                                        `${record.id}-${record.company}`
                                    }
                                    dataSource={
                                        selectCustomerState.availableCustomers
                                    }
                                    onRowClick={onSelect}
                                    pagination={selectCustomerState.request}
                                    isLoading={selectCustomerState.isFetching}
                                    onChange={loadData}
                                    initialValues={getInitialValues()}
                                    onFilterChange={onFiltersChange}
                                    filters={[
                                        <FormItem
                                            label={translate(
                                                'forms.labels.searchFor',
                                            )}
                                            name={'filterBy'}
                                        >
                                            <Select
                                                placeholder={translate(
                                                    'general.all',
                                                )}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {filterTypes.map((f) => {
                                                    return (
                                                        <Option
                                                            key={f.name}
                                                            value={f.name}
                                                        >
                                                            {f.description}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </FormItem>,
                                        <FormItem
                                            label={translate(
                                                'forms.labels.cia',
                                            )}
                                            name={'company'}
                                            style={{ minWidth: 150 }}
                                        >
                                            <Select
                                                placeholder={translate(
                                                    'general.all',
                                                )}
                                                dropdownMatchSelectWidth={false}
                                                allowClear
                                            >
                                                {selectCustomerState.availableCompanies.map(
                                                    (c) => (
                                                        <Option value={c.id}>
                                                            {c.id} - {c.name}
                                                        </Option>
                                                    ),
                                                )}
                                            </Select>
                                        </FormItem>,
                                        !!SHOW_CATEGORY && (
                                            <FormItem
                                                label={translate(
                                                    'forms.labels.category',
                                                )}
                                                name={'category'}
                                                style={{ minWidth: 150 }}
                                            >
                                                <Select
                                                    placeholder={translate(
                                                        'general.all',
                                                    )}
                                                    dropdownMatchSelectWidth={false}
                                                    loading={selectCustomerState.isFetchingCategories}
                                                    allowClear
                                                >
                                                    {selectCustomerState?.categories.map(
                                                        (c) => (
                                                            <Option value={c.nome}>
                                                                {c.nome}
                                                            </Option>
                                                        )
                                                    )}
                                                </Select>
                                            </FormItem>),
                                        <Form.Item wrapperCol={{ span: 24 }}>
                                            <Input
                                                placeholder={translate(
                                                    'forms.placeholders.search',
                                                )}
                                                allowClear
                                                suffix={
                                                    <div
                                                        onClick={search}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        <SearchOutlined />
                                                    </div>
                                                }
                                                onKeyUp={(e) => {
                                                    if (
                                                        e.code === `Enter` ||
                                                        e.keyCode === 13
                                                    ) {
                                                        search();
                                                    }
                                                }}
                                                onChange={(e) =>
                                                    onSearch(e.target.value)
                                                }
                                            />
                                        </Form.Item>,
                                    ]}
                                />
                            </Col>
                        </Row>
                    </ShadowContainer>
                </Col>
                <Col span={1} />
            </Row>
        </Page>
    );
};
