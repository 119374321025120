import '@extensions/string';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from '@axios';
import { translate } from '@components/i18n';
import { PaginatedResponseApi } from '@models/order/items/response';
import { RootState } from '@redux/reducers';
import { Distributor } from '@models/distributor';

export const fetchDistributors = 
    createAsyncThunk<PaginatedResponseApi<Distributor.Response>, Distributor.Params | void>('distributor/list', async (request, thunkAPI) => {
    try {
        const response = await axios.get<PaginatedResponseApi<Distributor.ResposeApi>>(`/cliente/distribuidores/byusuario`, {params: request});

        const { data, status } = response;

        if (status === 200) {
            return {
                ...data,
                content: data?.content.map(item => {
                    const temp = Object.assign(new Distributor.ResposeApi(), item)
                    
                    return temp.fromJSON()
                }) ||  []
            }
        } else {
            return thunkAPI.rejectWithValue('');
        }
    } catch (e) {
        return thunkAPI.rejectWithValue(translate('general.erroListingDistributors'));
    }
});


interface IDistributorState {
    available: PaginatedResponseApi<Distributor.Response>;
    isFetching: boolean;
    isSuccess: boolean;
    isError: boolean;
    errorMessage?: string;
    filter: string;
}

const initialState: IDistributorState = {
    available: undefined,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    filter: '',
};

const distributorSlice = createSlice({
    name: 'distributor-slice',
    initialState,
    reducers: {
        clearState: (state) => {
            state = { ...initialState };
            return state;
        },
        deleteTransferItemFromState: (state, action: PayloadAction<number>) => {
            state.available.content = state.available.content.filter((u) => u.id !== action.payload);
            return state;
        },
    },
    extraReducers: {
        [fetchDistributors.pending.toString()]: (state) => {
            state.isFetching = true;
            return state;
        },
        [fetchDistributors.rejected.toString()]: (state, action) => {
            state.errorMessage = action.payload;
            state.isFetching = false;
            state.isError = true;
            return state;
        },
        [fetchDistributors.fulfilled.toString()]: (
            state,
            { payload }: PayloadAction<PaginatedResponseApi<Distributor.Response>>,
        ) => {
            state.available = payload;
            state.isSuccess = true;
            state.isFetching = false;
            return state;
        },
    },
});

export const { clearState, deleteTransferItemFromState } = distributorSlice.actions;

export const distributorSelector = (state: RootState): IDistributorState => state.distributor;

export default distributorSlice.reducer;
