import { translate } from '@components/i18n';

export const ROLES = [
    { id: 'ADMINISTRADOR', name: translate('general.adm') },
    { id: 'APROVADOR', name: translate('general.approver') },
    { id: 'REPRESENTANTE', name: translate('general.representer') },
    { id: 'FATURAMENTO', name: translate('general.billing') },
    {
        id: 'CONTROLADORIA',
        name: translate('general.controllership'),
    },
    { id: 'FINANCEIRO', name: translate('general.financial') },
    { id: 'PROMOTOR', name: translate('general.promoter') },
];

export const REPORT_PERMISSION = [
    {
        id: "PEDIDOS_USUARIO",
        name: translate("general.onlyMyOrders")
    },
    {
        id: "PEDIDOS_DIVISAO",
        name: translate("general.onlyMyDivision")
    },
    {
        id: "PEDIDOS_TODOS",
        name: translate("general.totalAccess")
    },
]