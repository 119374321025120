import React, { memo, useCallback, useEffect, useState } from 'react';
import { Form, message, Row } from 'antd';
import { Button } from '@styles/components/button';
import { FormItem, Select, Option } from '@styles/components/form';
import { InputDate } from '@components/input';
import locale from 'antd/es/date-picker/locale/pt_BR';
import localeEs from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import { useQuery as useQueryParams } from '@hooks/use-query';
import { useHistory } from 'react-router-dom';
import { OrderType } from '@models/order/order-type';
import { useDispatch, useSelector } from 'react-redux';
import {
    addAvailablePlatform,
    customerSelector,
    fetchAvailablePlatforms,
    orderItemsSelector,
    orderTypeSelector,
    selectType,
    updateMoeda,
    updateOrder,
} from '@redux/slices/order';
import { PlatformRequest } from '@models/order/platform/request';
import { useQuery } from 'react-query';
import axios from '@axios';
import { TypeResponseApi } from '@models/order/type/response';
import { isEsAr, isEsMx, translate } from '@components/i18n';
import { DO_NOT_SHOW_MONEY_SELECTION } from '@config/env';

export const SelectTypeForm: React.FC = memo(() => {
    const query = useQueryParams()
    const isDraft = query.get('draft')
    const selectItemsState = useSelector(orderItemsSelector);

    const defaultValues: PlatformRequest = {
        date: selectItemsState?.order?.orderType?.date,
        platform: selectItemsState?.order?.orderType?.platform?.id,
        //companhiaCodigo:selectItemsState?.order?.customer?.cnpj ,
        customerId: selectItemsState?.order?.customer?.id,
        type: selectItemsState?.order?.orderType?.type?.id,
    }

    const history = useHistory();
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const selectTypeState = useSelector(orderTypeSelector);
    const selectCustomerState = useSelector(customerSelector);
    const [formState, setFormState] = useState<PlatformRequest>(isDraft ? defaultValues : undefined);

    const goBack = (): void => history.goBack();
    const { data, refetch } = useQuery('suggestion', async () => {
        if (!selectCustomerState?.customer?.code || !selectCustomerState?.customer?.company) {
            throw new Error()
        }

        const result = await axios.get<TypeResponseApi>(
            `/filial/byusuario/cliente/${selectCustomerState?.customer?.code}?companhiaCodigo=${selectCustomerState.customer.company}`,
        );

        const data = Object.assign(new TypeResponseApi(), result.data);

        return data.toTypeResponse();
    });

    useEffect(() => {
        if (isDraft) {
            dispatch(addAvailablePlatform({
                id: selectItemsState?.order?.orderType?.platform?.id,
                description: selectItemsState?.order?.orderType?.platform.description,
                conven: undefined
            }));
        }
    }, [isDraft])


    useEffect(() => {
        if (selectItemsState.moedas.length === 1) {
            form.setFieldsValue({ moneyType: selectItemsState.moedas[0].codigo });
            setFormState(prev => ({ ...prev, moneyType: selectItemsState.moedas[0].codigo }));
        }
    }, [selectItemsState.isFetchingMoeda])


    useEffect(() => {
        if (data && selectTypeState.availablePlatforms.length > 0) {
            form.setFieldsValue({ platform: data.id });
            setFormState({ ...formState, platform: data.id });
        } else refetch();
    }, [data, selectTypeState.availablePlatforms]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onChange = (_, data: any): void => {
        setFormState(prev => ({ ...prev, ...data }));

        if (data.type !== formState?.type) {
            dispatch(fetchAvailablePlatforms({ type: parseInt(data.type) }));
            refetch();
        }
    };

    const isValid = useCallback(() => {
        if (!!formState?.platform && !!formState?.type && !!formState.moneyType) return false;
        return true;
    }, [formState]);

    const onSubmit = (): void => {
        const moeda = selectItemsState.moedas.find(item => item.codigo === formState.moneyType)
        const moedaSimbolo = selectItemsState.moedaSimbolos.find(item => item.codigoMoeda === formState.moneyType)

        dispatch(updateMoeda({
            codigo: moeda?.codigo,
            descricao: moeda?.descricao,
            simbolo: moedaSimbolo?.simbolo,
        }))

        dispatch(
            selectType({
                ...formState,
                date: formState.date,
            }),
        );
        dispatch(
            updateOrder({
                orderType: {
                    ...formState,
                    date: formState.date,
                },
            }),
        );

        const ref = query.get('ref')

        let params = ''

        if (formState.type.toString() === 'S1') {

            if (isDraft) {
                params = `?draft=true&&ref=${ref}`
            }

            history.push('/create-order/select-items' + params);

        } else if (formState.type.toString() !== 'S1') {
            if (isDraft) {
                params = `?draft=true&marketing=true&ref=${ref}`
            }

            history.push('/order/marketing-bonification' + params);
        }
    };

    useEffect(() => {
        if (
            !selectTypeState.isFetchingPlatform &&
            selectTypeState.isSuccessPlatform &&
            selectTypeState.availablePlatforms.length === 0
        )
            message.error(translate('general.filialAvailableError'), 3);
    }, [selectTypeState.isFetchingPlatform, selectTypeState.isSuccessPlatform]);

    return (
        <Form
            form={form}
            onValuesChange={onChange}
            layout="vertical"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            initialValues={formState}
        >
            <FormItem label={translate('forms.labels.orderType')} name={'type'}>
                <Select
                    placeholder={translate('forms.placeholders.chooseOption')}
                    loading={selectTypeState.isFetching}
                    disabled={!!isDraft}
                >
                    {selectTypeState.availableTypes
                        .filter((f) => `${f.id}` !== 'V1')
                        .map((t) => (
                            <Option value={t.id}>{t.description}</Option>
                        ))}
                </Select>
            </FormItem>
            <FormItem
                label={translate('forms.labels.originPlatform')}
                name={'platform'}
            >
                <Select
                    placeholder={translate('forms.placeholders.chooseOption')}
                    loading={selectTypeState.isFetchingPlatform}
                    allowClear
                    disabled={!!isDraft}
                >
                    {selectTypeState.availablePlatforms.map((t) => (
                        <Option value={t.id}>
                            {t.id} - {t.description}
                        </Option>
                    ))}
                </Select>
            </FormItem>
            <Form.Item
                name={'date'}
                label={translate('forms.labels.scheduleOrder')}

            >
                <InputDate
                    locale={isEsAr() || isEsMx() ? localeEs : locale}
                    format={'DD/MM/YYYY'}
                    disabledDate={(current) => {
                        return moment() >= current;
                    }}
                    placeholder={translate('forms.placeholders.chooseDate')}
                />
            </Form.Item>
            {!DO_NOT_SHOW_MONEY_SELECTION && (
                <FormItem
                    label={translate('forms.labels.moneyType')}
                    name={'moneyType'}
                >
                    <Select
                        placeholder={translate('forms.placeholders.chooseOption')}
                        loading={selectItemsState.isFetchingMoeda}
                        allowClear
                        disabled={selectItemsState.moedas.length === 1}
                    >
                        {selectItemsState.moedas.map((t) => (
                            <Option value={t.codigo}>
                                {`${t.codigo} - ${t.descricao}`}
                            </Option>
                        ))}
                    </Select>
                </FormItem>
            )}
            <Form.Item>
                <Row justify={'space-between'}>
                    <Button
                        htmlType="button"
                        type="primary"
                        ghost
                        fontWeight={500}
                        onClick={goBack}
                    >
                        {translate('forms.buttons.changeClient')}
                    </Button>
                    <Button
                        htmlType="submit"
                        type="primary"
                        fontWeight={500}
                        onClick={onSubmit}
                        disabled={isValid()}
                    >
                        {translate('forms.buttons.continue')}
                    </Button>
                </Row>
            </Form.Item>
        </Form>
    );
});
