import React, { memo, useContext } from 'react';
import { translate } from '@components/i18n';
import { Table } from '@components/table';
import { Col } from 'antd';
import { useSelector } from 'react-redux';
import { ModalContext } from 'src/contexts/modal-context';
import {  columns } from '../constants';
import { EditModal } from './edit-modal';
import { transferClientsSelector } from '@redux/slices/transfer-clients';
import { TransferClient } from '@models/transfer-client';

export const Body: React.FC = memo(() => {
    const clientState = useSelector(transferClientsSelector)
    const modalContext = useContext(ModalContext);

    const edit = (
        transferClient: TransferClient.Response,
        index: number,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
    ): void => {
        if (
            !`${event.target}`.includes('SVG') &&
            !`${event.target}`.includes('Button') &&
            !`${event.target}`.includes('Span')
        )
            modalContext.setIsOpen(
                true,
                <EditModal
                    data={transferClient}
                    key={`editing-client-${transferClient.nome.split(' ')[0]}`}
                />,
                {
                    title: `${translate('general.editClient')} - ${
                        transferClient.nome.split(' ')[0]
                    }`,
                    width: '40%',
                },
            );
    };

    return (
        <Col>
            <Table
                columns={columns}
                rowKey={(record: TransferClient.Response) => `${record.id}`}
                dataSource={clientState?.available?.content || []}
                pagination={undefined}
                pointer
                onRowClick={edit}
                scroll={{ y: 'calc(100vh - 380px)' }}
            />
        </Col>
    );
});
